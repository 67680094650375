  <div class="row">
    <div class="col-sm-12 deliveries">
      <h2>Deliveries</h2>
      <p>Murray Bridge flat rate $7 Mon to Sat<br/>
        Everywhere else will vary depending on freight Company.</p>
      <p>Orders to be placed the day before, for next day delivery.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 delivery-left">
			<h2>We deliver every weekday to:</h2>
			<ul>
					<li>Mannum, Nildottie and Swan Reach</li>
					<li>Meningie, Narrung, Wellington East</li>
					<li>Lameroo, Pinnaroo and along the Mallee Highway</li>
					<li>Coomandook, Coonalpyn and Tintinara</li>
					<li>Callington, Kanmantoo and Monarto</li>
					<li>Karoonda</li>
					<li>Tailem Bend, Wellington and Jervois (Mon, Wed, Fri)</li>
					<li>Murrayville (Thu)</li>
					<li>Keith (Tues, Thur)</li>
				</ul>
    </div>
    <div class="col-sm-6 delivery-right">
      <p>
				<img src="/assets/images/map.jpg" alt="delivery map" width="70%">
      </p>
    </div>
  </div>
